import "./App.css";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import ImageList from "./components/ImageList";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <ResponsiveAppBar title="MyGrowNow"></ResponsiveAppBar>
        <ImageList></ImageList>
      </div>
    </ThemeProvider>
  );
}

export default App;
