import React, { useEffect, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

export default function StandardImageList() {
  // const [entries, setEntries] = useState;

  // useEffect(() => {
  //   //console.log("entries changed");
  // }, [entries]);

  return (
    <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
      <ImageListItem></ImageListItem>
    </ImageList>
  );
}
